import React, {useEffect, useState, Scr} from "react"
import {graphql, Link, useStaticQuery} from "gatsby"
import Navbar from 'react-bootstrap/Navbar'
import useSiteMetadata from "gatsby-theme-nieuwbouw/src/hooks/SiteMetadata"
import {Container} from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Downloads from "gatsby-theme-nieuwbouw/src/components/project/Downloads";
// @ts-ignore
import logo from '../../../img/logo.png'
// @ts-ignore
import logo_simple from '../../../img/logo_simple.png'

interface MenuItem {
    link: string;
    external: boolean;
    name: string;
}

interface Props {
    isHome?: boolean
}
// https://codepen.io/mmgolden/pen/JNewdL
export default ({isHome = false}: Props) => {
    const [scrollTop, setScrollTop] = useState(0);

    useEffect(() => {
        const onScroll = (e) => {
            setScrollTop(e.target.documentElement.scrollTop);
        };
        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);
    }, [scrollTop]);

    const [downloadsOpen, setDownloadsOpen] = useState(false)
    const data = useStaticQuery(graphql`
        query Brochure {
            media(titel: {eq: "Brochure Vaartsche Weelde"}) {
                ...Document
            }
            project {
                documenten {
                    ...Document
                }
            }
        }
    `)

    const {menuItems} = useSiteMetadata()

    return (
        <Navbar variant='light' fixed={isHome ? 'top': undefined} bg={isHome && scrollTop ? 'light' : undefined } expand='lg' collapseOnSelect={true} style={{
            background: 'transparent'
        }}>
            <Container fluid={'xl'} className={'position-relative'}>
                <Navbar.Brand className={'mobile-brand d-lg-none'}>
                    <Link to="/">
                        <img height={40} src={logo_simple} alt={'logo'}
                             style={{maxWidth: '80%'}}
                        />
                    </Link>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav"  />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="justify-content-around w-100">
                        {menuItems.map((item: MenuItem, i: number) => (
                            <Nav.Item key={i}>
                                {item.external
                                    ?
                                    <Nav.Link href={item.link} rel="noopener noreferrer" target="_blank">{item.name}</Nav.Link>
                                    :
                                    <Link to={item.link} className="nav-link" activeClassName="active">{item.name}</Link>
                                }
                            </Nav.Item>
                        ))
                        }
                    </Nav>
                    <Navbar.Brand className={'mx-auto d-none d-lg-block'}>
                        <Link to="/">
                            <img width={150} src={isHome && !scrollTop ? logo : logo_simple} alt={'logo'} />
                        </Link>
                    </Navbar.Brand>
                    <Nav className="justify-content-around w-100">
                        <Nav.Item>
                            <Link to={'/omgevingsvisie'} className="nav-link" activeClassName="active">
                                Omgevingsvisie
                            </Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link
                                disabled={!Boolean(data.media?.localFile?.publicURL)}
                                href={data.media?.localFile?.publicURL}
                            >
                                Brochure
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Downloads project={data.project} isOpen={downloadsOpen} onRequestClose={() => setDownloadsOpen(false)} />
                            <Nav.Link onClick={() => setDownloadsOpen(true)}>
                                Downloads
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Link to={'/contact'} className="nav-link" activeClassName="active">
                                Contact
                            </Link>
                        </Nav.Item>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}