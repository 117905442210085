import React from 'react'
import SocialMenu from 'gatsby-theme-nieuwbouw/src/components/Social'
import {Col, Container, Nav, Row} from 'react-bootstrap'
import {StaticImage} from "gatsby-plugin-image";

const Footer = () => {
    return (
        <footer className="footer bg-secondary py-4 text-center">
            <Container>
                <Row className={'align-items-end'}>
                    <Col md={5}>
                        <Nav>
                            <SocialMenu />
                        </Nav>
                    </Col>
                    <Col md={2} className={'text-center'}>
                        <StaticImage
                            className={'mx-auto'}
                            imgClassName={'mx-auto'}
                            src={'../../img/logo_footer.png'}
                            alt={'logo'}
                            width={120}
                            height={185}
                            layout={'fixed'}
                        />
                    </Col>
                    <Col md={5} className={'text-md-right'}>
                        <p className="text-muted text-xs mb-0 text-uppercase">
                            <small>© Copyright De Realisatie {new Date().getFullYear()} | <a href="/algemenevoorwaarden.pdf">Algemene voorwaarden</a></small>
                        </p>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}

export default Footer
