module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-41576983-9","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Vaartsche Weelde","short_name":"Vaartsche Weelde","start_url":"/","icon":"/codebuild/output/src364670461/src/vaartscheweelde.nl/src/img/icon.png","background_color":"#ffffff","theme_color":"#D75A27","display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"16d3932e5952235752eb74ccefb384a6"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-theme-nieuwbouw/gatsby-browser.js'),
      options: {"plugins":[],"referral":"vaartscheweelde.nl","objectcode":"N100022","afdelingscode":"907366","siteUrl":"https://www.vaartscheweelde.nl","trackingId":"UA-41576983-9","social":{"facebook":"","instagram":"","linkedin":""},"menuItems":[{"name":"Zeewolde","link":"/zeewolde","external":false},{"name":"Situatie","link":"/situatie","external":false},{"name":"Woningen","link":"/woningen","external":false},{"name":"Beschikbaar","link":"/beschikbaar","external":false},{"name":"Extra","link":"/extra","external":false}],"title":"Vaartsche Weelde","titleShort":"Vaartsche Weelde","background_color":"#ffffff","theme_color":"#D75A27"},
    }]
